import './App.css';
import { Flex, Box, Center } from '@chakra-ui/react';
import Upload from './components/Upload';
import ModelViewer from './components/ModelViewer';
import { useApp } from './contexts/App';

function App() {
  const { model } = useApp();
  return (
    <Flex
      h={{ base: '100%', lg: '100vh' }}
      flexDirection={{
        base: 'column-reverse',
        lg: 'row',
      }}
    >
      <Box
        flex="1"
        minH="100vh"
        borderRight={{ base: 'none', lg: '5px dashed #a537fd' }}
      >
        <Center h="100vh" bg="#edf2f7" p="4">
          <Upload />
        </Center>
      </Box>
      <Box flex="1" minH="100vh" bg="#edf2f7">
        <Center h="100vh">
          <ModelViewer src={model} />
        </Center>
      </Box>
    </Flex>
  );
}

export default App;
