import { useState, useEffect, createContext, useContext } from 'react';
import Loader from '../components/Loader/Loader';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [model, setModel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <AppContext.Provider value={{ model, setModel }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
