import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme, ColorModeScript } from '@chakra-ui/react';
import { AppProvider } from './contexts/App';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  components: { Button: { baseStyle: { _focus: { boxShadow: 'none' } } } },
  shadows: {
    outline: 'none',
  },
});

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <AppProvider>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
    </AppProvider>
  </ChakraProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
