import React, { useState, useCallback } from 'react';
import { useToast } from '@chakra-ui/react';
import '../styles/polaris.css';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider, Card, DropZone, Select } from '@shopify/polaris';
import ModalHelp from './ModalHelp';
import { useApp } from '../contexts/App';

const models = [
  {
    label: 'Astronaut',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/Astronaut.glb?v=1614713766',
  },
  {
    label: 'Shish Kebab',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/shishkebab.glb?v=1614714163',
  },
  {
    label: 'Reflective sphere',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/reflective-sphere.gltf?v=1614714160',
  },
  {
    label: 'Damaged helmet',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/DamagedHelmet.glb?v=1614713767',
  },
  {
    label: 'Barramundi fish',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/BarramundiFish.mixed.glb?v=1614714166',
  },
  {
    label: 'Deck chair',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/Chair.glb?v=1614714168',
  },
  {
    label: 'Sheen chair',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/SheenChair.glb?v=1614714168',
  },
  {
    label: 'Mixer',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/Mixer.glb?v=1614714165',
  },
  {
    label: 'Geo planter',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/GeoPlanter.glb?v=1614714169',
  },
  {
    label: 'Toy train',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/ToyTrain.glb?v=1614714169',
  },
  {
    label: 'Canoe',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/Canoe.glb?v=1614714166',
  },
  {
    label: 'Wall art',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/boom_2.glb?v=1614714170',
  },
  {
    label: 'Robot',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/RobotExpressive.glb?v=1614714161',
  },
  {
    label: 'Horse',
    value:
      'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/Horse.glb?v=1614714159',
  },
];

const Upload = () => {
  const toast = useToast();
  const { model, setModel } = useApp();

  const [files, setFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleModalOpen = () => {
    setIsOpen(true);
  };

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, rejectedFiles) => {
      console.log(acceptedFiles[0].size);
      if (acceptedFiles[0].size <= 15049052) {
        setModel(window.URL.createObjectURL(acceptedFiles[0]));
      } else {
        toast({
          title: 'Error',
          description: 'File size too large',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
      }
    },
    []
  );

  const handleSelectChange = useCallback((value) => setModel(value), []);

  const fileUpload = !files.length && <DropZone.FileUpload />;

  return (
    <>
      <AppProvider
        i18n={enTranslations}
        style={{ height: '100%', width: '100%' }}
      >
        <Card
          sectioned
          title="Upload 3D model"
          actions={[
            {
              content: 'Help',
              onAction: handleModalOpen,
            },
          ]}
        >
          <DropZone
            accept=".glb,.gltf"
            allowMultiple={false}
            onDrop={handleDropZoneDrop}
          >
            {fileUpload}
          </DropZone>
          <p style={{ textAlign: 'center', margin: '30px' }}>or pick one</p>
          <Select
            options={models}
            onChange={handleSelectChange}
            value={model}
          />
        </Card>
      </AppProvider>
      <ModalHelp open={isOpen} close={() => setIsOpen(false)} />
    </>
  );
};

export default Upload;
