import React from 'react';
import './loader.css';

const Loader = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -45%)',
      }}
    >
      <div className="border-loading-indicator col-1 row-1"></div>
      <div className="border-loading-indicator col-2 row-1"></div>
      <div className="border-loading-indicator col-3 row-1"></div>
    </div>
  );
};

export default Loader;
