import React, { useState, useEffect } from 'react';
import '@google/model-viewer';
import { Progress } from '@chakra-ui/react';

const ModelViewer = ({ src }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const onProgress = (event) => {
      console.log(event);
      if (event.detail.totalProgress === 1) {
        setTimeout(() => {
          setIsLoading(false);
        }, 1500);
      } else {
        setIsLoading(true);
      }
    };
    document
      .querySelector('#model-viewer')
      .addEventListener('progress', onProgress);
    return () => {
      document
        .querySelector('#model-viewer')
        .removeEventListener('progress', onProgress);
    };
  }, []);

  return (
    <>
      <model-viewer
        id="model-viewer"
        src={
          src ||
          'https://cdn.shopify.com/s/files/1/0080/8062/1648/files/Astronaut.glb?v=1614713766'
        }
        alt="3D Model"
        shadow-intensity="1"
        auto-rotate
        camera-controls
        style={{
          maxWidth: '750px',
          maxHeight: '750px',
          width: '100vw',
          height: '100vh',
          backgroundColor: 'transparent',
          '--poster-color': 'transparent',
          '--progress-bar-color': 'transparend',
          '--progress-bar-height': '100vh',
          '--progress-mask': 'transparent',
        }}
      >
        {isLoading && (
          <Progress
            maxWidth="750px"
            maxHeight="750px"
            h="100%"
            w="100"
            zIndex="9999"
            colorScheme="purple"
            isIndeterminate
          />
        )}
      </model-viewer>
    </>
  );
};

export default ModelViewer;
